<template>
  <ItemList
    @addItem="addCancelReason"
    @removeItem="removeCancelReason"
    :items="cancelReasons"
    :isLoading="isLoadingList"
    noDataText="Nenhum Motivo de cancelamento encontrado"
    label="Motivos de cancelamento"
  />
</template>

<script>
export default {
  components: {
    ItemList: () => import('./ItemList.vue'),
  },
  data: () => ({
    cancelReasons: [],
    isLoadingList: false,
  }),
  methods: {
    getCancelReasons() {
      this.isLoadingList = true
      this.$api
        .get(`cancel_reasons`)
        .then((res) => {
          this.cancelReasons = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingList = false
        })
    },
    addCancelReason(value) {
      this.$api
        .post('cancel_reasons/store', { name: value })
        .then((res) => {
          this.cancelReasons.push(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeCancelReason(item) {
      this.$api
        .post('cancel_reasons/delete', item)
        .then(() => {
          let idx = this.cancelReasons.indexOf(item)
          this.cancelReasons.splice(idx, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.cancelReasons.length === 0) {
      this.getCancelReasons()
    }
  },
}
</script>

<style>
</style>